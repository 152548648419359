import {makeAutoObservable, runInAction} from "mobx";
import http from "../framework/http.js";

class UserInfoStore {
    activationStatus = null;
    displayName = null;
    cartAmount = null;

    constructor() {
        makeAutoObservable(this);
        if (window.localStorage.getItem("token")) {
            this.initUserInfo();
        }
    }

    setUserInfo(user) {
        this.activationStatus =user.activationStatus;
        this.displayName = user.displayName;
        this.cartAmount = user.cartAmount;
    }

    async initUserInfo() {
        const res = await http.get("/getUserInfo");
        if (res.data && res.data.code === 200) {
            this.setUserInfo(res.data.data);
        }
    }

}

const userInfoStore = new UserInfoStore();
export default userInfoStore;
