import React, {useEffect, useRef, useState} from 'react';
import {
    AutoComplete,
    Button, Checkbox, Col, Form, Input, message, Modal, Row, Select, Space, Switch, Tooltip, Typography
} from "antd";
import {QuestionCircleFilled} from "@ant-design/icons";
import "./Register.css";
import http from "../framework/http";
import userInfoStore from "../store/UserInfo.Store";
import {useLocation, useNavigate} from "react-router-dom";


function Register() {
    const {Title} = Typography;
    const [messageApi, messageContextHolder] = message.useMessage();
    const navigate = useNavigate();
    const location = useLocation(); // 获取当前路由信息
    const from = (location.state?.from === "/login" || location.state?.from === "/register") ? "/" : (location.state?.from || "/");
    const [form] = Form.useForm();
    const eircodeInputRef = useRef();
    const eircodeInputValue = Form.useWatch("companyEircode", form);
    const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
    const [emailInputStatus, setEmailInputStatus] = useState();
    const [isGetVerificationCodeButtonDisabled, setIsGetVerificationCodeButtonDisabled] = useState(false);
    const [isGetVerificationCodeButtonLoading, setIsGetVerificationCodeButtonLoading] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [showCompanyOptions, setShowCompanyOptions] = useState(false);
    const [eircodeInputSelectionStart, setEircodeInputSelectionStart] = useState(0);
    const [addressOptions, setAddressOptions] = useState();
    const [searchEircode, setSearchEircode] = useState("");
    const [isAddressNeeded, setIsAddressNeeded] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            }, sm: {
                span: 8,
            },
        }, wrapperCol: {
            xs: {
                span: 24,
            }, sm: {
                span: 16,
            },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24, offset: 0,
            }, sm: {
                span: 16, offset: 8,
            },
        },
    };

    useEffect(() => {
        eircodeInputRef.current.setSelectionRange(eircodeInputSelectionStart, eircodeInputSelectionStart);
    }, [eircodeInputValue]);

    const validateEmail = async (_, email) => {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            return Promise.reject();
        }

        setEmailInputStatus("validating");
        var res = await http.get("/checkEmail", {params: {email: email}});

        if (res.data.code === 200) {
            if (!res.data.data) {
                setEmailInputStatus("success");
                return Promise.resolve();
            } else {
                setEmailInputStatus();
                return Promise.reject(new Error('Email is already in use'));
            }
        } else {
            message.error(res.data.msg);
            setEmailInputStatus();
            return Promise.reject(new Error('Email validation failed'));
        }
    }

    const getVerificationCode = async () => {
        try {
            setIsGetVerificationCodeButtonLoading(true);
            await form.validateFields(['email']);// validate email, if failed, there will be error threw

            const email = form.getFieldValue('email');
            const res = await http.get("/getVerificationCode", {params: {email: email}});

            if (res.data.code === 200) {
                message.success('Verification code sent successfully!');
                startCountdown();
            } else {
                message.error(res.data.msg || 'Failed to send verification code.');
            }
            setIsGetVerificationCodeButtonLoading(false);
        } catch (error) {
            form.scrollToField('email');
            setIsGetVerificationCodeButtonLoading(false);
        }
    }

    const startCountdown = () => {
        setIsGetVerificationCodeButtonDisabled(true);
        setCountdown(60);
        const timer = setInterval(() => {
            setCountdown(prevCount => {
                if (prevCount === 1) {
                    clearInterval(timer);
                    setIsGetVerificationCodeButtonDisabled(false);
                    return 0;
                }
                return prevCount - 1;
            });
        }, 1000);
    };

    const getFullAddress = async (value) => {
        // filter incomplete eircode
        if (value?.length !== 8) {
            setAddressOptions();
            return;
        }
        // filter pure number eircode, could be id auto backfill made by AutoComplete
        if (/^\d+$/.test(value.replace(' ', ''))) {
            return;
        }
        // filter repeat input
        if (value === searchEircode && addressOptions) {
            return;
        }
        setSearchEircode(value);
        var res = await http.get("/getFullAddress", {params: {eircode: value}});

        if (res.data?.code === 200) {
            setAddressOptions(res.data.data?.map((item) => {
                return {
                    label: <span style={{whiteSpace: "normal"}}>{item.fullAddress}</span>,
                    value: item.id.toString(),
                    address: item.fullAddress
                }
            }));
        }
    };

    const submitForm = async (formData) => {
        messageApi.open({
            type: 'loading',
            content: 'registering...',
            duration: 0
        });

        // collect and process data
        const data = {};
        data.email = formData.email;
        data.password = formData.password;
        if (formData.mobile) {
            data.mobile = formData.mobilePrefix + " " + formData.mobile.trim();
        }
        data.firstName = formData.firstName?.trim();
        data.surname = formData.lastName?.trim();
        if (!formData.isCompany) {
            data.isCompany = 0;
        } else if (formData.isCompany === true) {
            if (formData.vat?.trim()) {
                data.isCompany = 1;
            } else {
                data.isCompany = 2;
            }
        }
        if (formData.isCompany) {
            data.companyName = formData.companyName.trim();
            data.vat = formData.vat?.trim();
            if (formData.tel) {
                data.tel = formData.telPrefix + " " + formData.tel.trim();
            }
            data.companyEircode = formData.companyEircode;
            data.companyAddress = formData.companyAddress?.replace(/\n+/g, ", ").replace(/\s+,/g, ',').replace(",", ", ").replace(/\s+/g, ' ').trim();
            if (data.mobile && data.firstName && data.companyEircode?.length === 8 && data.companyAddress) {
                data.addSameDeliveryAddress = formData.addSameDeliveryAddress;
            }
        }
        data.verificationCode = formData.verificationCode;

        console.log(data)

        const res = await http.post("/register", data);
        console.log(res.data)//

        if (res.data?.code === 200) {
            window.localStorage.setItem("token", res.data.token);
            userInfoStore.setUserInfo(res.data.data);
            messageApi.destroy();
            message.success("login success");
            navigate(from, {replace: true});
        } else {
            messageApi.destroy();
            message.error(res.data?.msg || "Error, please try again later");
        }
    };

    return (<div id="registerBody">
        {messageContextHolder}
        <div id="formContainer">
            <Form labelWrap form={form} onFinish={submitForm} {...formItemLayout}>
                <Title level={3} style={{textAlign: "center", margin: "0px 0px 25px 0px"}}>Register</Title>
                <Form.Item
                    name="email"
                    label="Email"
                    hasFeedback
                    help={emailInputStatus === "success" ? "Email is available" : emailInputStatus === "validating" ? "validating..." : undefined}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                        {
                            required: true, message: 'Email cannot be empty!',
                        },
                        {
                            type: 'email', message: 'Please input a valid Email!',
                        },
                        {
                            validator: validateEmail, validateTrigger: "onBlur"
                        }]}
                >
                    <Input disabled={emailInputStatus === "validating"} maxLength={128} onChange={()=>{setEmailInputStatus();}}/>
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[{
                        required: true, message: 'Please input a password!',
                    }, {
                        validator: (_, value) => {
                            if (!value) {
                                return Promise.reject();
                            }
                            const invalidCharactersRegex = /[^A-Za-z\d@$!%*?&.]/;// only listed can be accepted
                            const complexityRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*(\d|[@$!%*?&.]))/;// at least one upper,one lower and one number or special
                            const minLen = 8;
                            const maxLen = 32;

                            if (invalidCharactersRegex.test(value)) {
                                return Promise.reject(new Error('Password contains invalid characters. Only letters, numbers, and @$!%*?&. are allowed.'));
                            }
                            if (!complexityRegex.test(value)) {
                                return Promise.reject(new Error('Password must contain at least one uppercase letter, one lowercase letter and at least one number or special character.'));
                            }
                            if (value.length < minLen) {
                                return Promise.reject(new Error("Password must be at least " + minLen + " characters!"));
                            }
                            if (value.length > maxLen) {
                                return Promise.reject(new Error("Password cannot be longer than " + maxLen + " characters"));
                            }
                            return Promise.resolve();
                        },
                    }]}
                    hasFeedback
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[{
                        required: true, message: 'Please confirm your password!',
                    }, ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match!'));
                        },
                    }),]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    name="mobile"
                    label="Mobile Number"
                    rules={[{
                        required: isAddressNeeded, message: 'Please input your mobile number!',
                    },]}
                >
                    <Input
                        maxLength={50}
                        addonBefore={<Form.Item name="mobilePrefix" noStyle initialValue={"+353"}>
                            <Select style={{width: 100,}}>
                                <Select.Option value="+1">+1</Select.Option>
                                <Select.Option value="+20">+20</Select.Option>
                                <Select.Option value="+233">+233</Select.Option>
                                <Select.Option value="+234">+234</Select.Option>
                                <Select.Option value="+27">+27</Select.Option>
                                <Select.Option value="+30">+30</Select.Option>
                                <Select.Option value="+31">+31</Select.Option>
                                <Select.Option value="+32">+32</Select.Option>
                                <Select.Option value="+33">+33</Select.Option>
                                <Select.Option value="+34">+34</Select.Option>
                                <Select.Option value="+352">+352</Select.Option>
                                <Select.Option value="+353">+353</Select.Option>
                                <Select.Option value="+354">+354</Select.Option>
                                <Select.Option value="+358">+358</Select.Option>
                                <Select.Option value="+372">+372</Select.Option>
                                <Select.Option value="+373">+373</Select.Option>
                                <Select.Option value="+374">+374</Select.Option>
                                <Select.Option value="+375">+375</Select.Option>
                                <Select.Option value="+376">+376</Select.Option>
                                <Select.Option value="+380">+380</Select.Option>
                                <Select.Option value="+39">+39</Select.Option>
                                <Select.Option value="+41">+41</Select.Option>
                                <Select.Option value="+420">+420</Select.Option>
                                <Select.Option value="+421">+421</Select.Option>
                                <Select.Option value="+44">+44</Select.Option>
                                <Select.Option value="+46">+46</Select.Option>
                                <Select.Option value="+47">+47</Select.Option>
                                <Select.Option value="+48">+48</Select.Option>
                                <Select.Option value="+49">+49</Select.Option>
                                <Select.Option value="+52">+52</Select.Option>
                                <Select.Option value="+54">+54</Select.Option>
                                <Select.Option value="+55">+55</Select.Option>
                                <Select.Option value="+60">+60</Select.Option>
                                <Select.Option value="+61">+61</Select.Option>
                                <Select.Option value="+62">+62</Select.Option>
                                <Select.Option value="+63">+63</Select.Option>
                                <Select.Option value="+64">+64</Select.Option>
                                <Select.Option value="+65">+65</Select.Option>
                                <Select.Option value="+66">+66</Select.Option>
                                <Select.Option value="+7">+7</Select.Option>
                                <Select.Option value="+81">+81</Select.Option>
                                <Select.Option value="+82">+82</Select.Option>
                                <Select.Option value="+84">+84</Select.Option>
                                <Select.Option value="+86">+86</Select.Option>
                                <Select.Option value="+87">+87</Select.Option>
                                <Select.Option value="+880">+880</Select.Option>
                                <Select.Option value="+91">+91</Select.Option>
                                <Select.Option value="+92">+92</Select.Option>
                                <Select.Option value="+94">+94</Select.Option>
                                <Select.Option value="+964">+964</Select.Option>
                                <Select.Option value="+966">+966</Select.Option>
                                <Select.Option value="+971">+971</Select.Option>
                                <Select.Option value="+972">+972</Select.Option>
                            </Select>
                        </Form.Item>}
                    />
                </Form.Item>
                <Form.Item name="firstName"
                           label="First Name"
                           rules={[{
                               required: isAddressNeeded, message: 'This field cannot be empty!',
                           },]}
                >
                    <Input maxLength={64}/>
                </Form.Item>
                <Form.Item name="lastName"
                           label="Last Name"
                >
                    <Input maxLength={64}/>
                </Form.Item>
                <Form.Item{...tailFormItemLayout}>
                    <Space>
                        <p>
                            I am trader
                            <Tooltip overlayInnerStyle={{textAlign: "justify"}} title={<>
                                A trader account will be activated automatically when VAT number is provided.<br/>
                                An unactivated trader account can only browse in wholesale prices but cannot place an
                                order.<br/>
                                If you do not have a VAT number, please contact us at +353 1 6301214 or email:
                                info@dublincomputer.ie to activate your trader account.
                            </>}>
                                <QuestionCircleFilled style={{color: "grey", marginLeft: 2}}/>
                            </Tooltip>
                        </p>
                        <Form.Item
                            name="isCompany"
                            valuePropName="checked"
                            noStyle
                        >
                            <Switch onChange={(checked) => {
                                setShowCompanyOptions(checked);
                                if (checked) {
                                    setIsAddressNeeded(form.getFieldValue("addSameDeliveryAddress"));
                                    form.validateFields(['firstName', 'mobile', 'companyEircode', 'companyAddress']);
                                } else {
                                    setIsAddressNeeded(false);
                                    form.validateFields(['firstName', 'mobile']);
                                }
                            }}/>
                        </Form.Item>
                    </Space>
                </Form.Item>
                <Form.Item name="companyName"
                           label="Company/Shop Title"
                           hidden={!showCompanyOptions}
                           rules={[{
                               required: showCompanyOptions, message: 'Company name cannot be empty!',
                           }]}
                >
                    <Input maxLength={255}/>
                </Form.Item>
                <Form.Item name="vat"
                           label="VAT Number"
                           hidden={!showCompanyOptions}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="tel"
                    label="Company Tel"
                    hidden={!showCompanyOptions}
                >
                    <Input
                        maxLength={50}
                        addonBefore={<Form.Item name="telPrefix" noStyle initialValue={"+353"}>
                            <Select style={{width: 100,}}>
                                <Select.Option value="+1">+1</Select.Option>
                                <Select.Option value="+20">+20</Select.Option>
                                <Select.Option value="+233">+233</Select.Option>
                                <Select.Option value="+234">+234</Select.Option>
                                <Select.Option value="+27">+27</Select.Option>
                                <Select.Option value="+30">+30</Select.Option>
                                <Select.Option value="+31">+31</Select.Option>
                                <Select.Option value="+32">+32</Select.Option>
                                <Select.Option value="+33">+33</Select.Option>
                                <Select.Option value="+34">+34</Select.Option>
                                <Select.Option value="+352">+352</Select.Option>
                                <Select.Option value="+353">+353</Select.Option>
                                <Select.Option value="+354">+354</Select.Option>
                                <Select.Option value="+358">+358</Select.Option>
                                <Select.Option value="+372">+372</Select.Option>
                                <Select.Option value="+373">+373</Select.Option>
                                <Select.Option value="+374">+374</Select.Option>
                                <Select.Option value="+375">+375</Select.Option>
                                <Select.Option value="+376">+376</Select.Option>
                                <Select.Option value="+380">+380</Select.Option>
                                <Select.Option value="+39">+39</Select.Option>
                                <Select.Option value="+41">+41</Select.Option>
                                <Select.Option value="+420">+420</Select.Option>
                                <Select.Option value="+421">+421</Select.Option>
                                <Select.Option value="+44">+44</Select.Option>
                                <Select.Option value="+46">+46</Select.Option>
                                <Select.Option value="+47">+47</Select.Option>
                                <Select.Option value="+48">+48</Select.Option>
                                <Select.Option value="+49">+49</Select.Option>
                                <Select.Option value="+52">+52</Select.Option>
                                <Select.Option value="+54">+54</Select.Option>
                                <Select.Option value="+55">+55</Select.Option>
                                <Select.Option value="+60">+60</Select.Option>
                                <Select.Option value="+61">+61</Select.Option>
                                <Select.Option value="+62">+62</Select.Option>
                                <Select.Option value="+63">+63</Select.Option>
                                <Select.Option value="+64">+64</Select.Option>
                                <Select.Option value="+65">+65</Select.Option>
                                <Select.Option value="+66">+66</Select.Option>
                                <Select.Option value="+7">+7</Select.Option>
                                <Select.Option value="+81">+81</Select.Option>
                                <Select.Option value="+82">+82</Select.Option>
                                <Select.Option value="+84">+84</Select.Option>
                                <Select.Option value="+86">+86</Select.Option>
                                <Select.Option value="+87">+87</Select.Option>
                                <Select.Option value="+880">+880</Select.Option>
                                <Select.Option value="+91">+91</Select.Option>
                                <Select.Option value="+92">+92</Select.Option>
                                <Select.Option value="+94">+94</Select.Option>
                                <Select.Option value="+964">+964</Select.Option>
                                <Select.Option value="+966">+966</Select.Option>
                                <Select.Option value="+971">+971</Select.Option>
                                <Select.Option value="+972">+972</Select.Option>
                            </Select>
                        </Form.Item>}
                    />
                </Form.Item>
                <Form.Item name="companyEircode"
                           label="Company Eircode"
                           hidden={!showCompanyOptions}
                           normalize={(value, prevValue) => {
                               // format
                               let newValue = value;
                               let selectionStart = eircodeInputRef.current.input.selectionStart;
                               let selectionStartPrev = eircodeInputSelectionStart;
                               // if (prevValue?.length - value.length === 1 && !value.includes(" ")) {
                               if (prevValue?.length - value.length === 1 && prevValue.replace(' ', '') === value.replace(' ', '')) {
                                   if (selectionStart !== selectionStartPrev) {
                                       // Backspace
                                       newValue = newValue.slice(0, 2) + newValue.slice(3);
                                   } else {
                                       // Del
                                       newValue = newValue.slice(0, 3) + newValue.slice(4);
                                   }
                               }
                               newValue = newValue.replace(/\s+/g, "").toUpperCase();
                               if (newValue.length >= 3) {
                                   newValue = newValue.slice(0, 3) + " " + newValue.slice(3);
                               }
                               if (newValue.length > 8) {
                                   newValue = newValue.slice(0, 8);
                               }
                               // correct selection position
                               if (selectionStartPrev < selectionStart) {
                                   while (selectionStartPrev <= selectionStart) {
                                       if (selectionStartPrev === 3) {
                                           selectionStart++;
                                           break;
                                       }
                                       selectionStartPrev++;
                                   }
                               }
                               if (selectionStartPrev === 4 && selectionStart === 3) {
                                   selectionStart--;
                               }
                               setEircodeInputSelectionStart(selectionStart);

                               // call getFullAddress to record eircode then conduct request
                               getFullAddress(newValue);

                               return newValue;
                           }}
                           rules={[
                               {
                                   required: isAddressNeeded, message: 'This field cannot be empty!',
                               },
                               {
                                   len: 8, message: "Incomplete input"
                               }
                           ]}
                >
                    <AutoComplete options={addressOptions} onSelect={(_, option) => {

                        form.setFieldValue("companyEircode", searchEircode); // recover with eircode recorded after AutoComplete auto backfill
                        form.setFieldValue("companyAddress", option.address);
                        form.validateFields(["companyAddress"]);
                    }}>
                        <Input
                            ref={eircodeInputRef}
                            onKeyDown={(e) => {
                                setEircodeInputSelectionStart(eircodeInputRef.current.input.selectionStart);
                                if (/[^a-zA-Z0-9]/g.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </AutoComplete>
                </Form.Item>
                <Form.Item name="companyAddress"
                           label="Company Address"
                           hidden={!showCompanyOptions}
                           rules={[{
                               required: isAddressNeeded, message: 'This field cannot be empty!',
                           },]}
                >
                    <Input.TextArea
                        style={{height: 80}}
                        maxLength={500}
                        showCount
                    />
                </Form.Item>
                {/*<Form.Item {...tailFormItemLayout} hidden={showCompanyOptions}>*/}
                {/*    <Tooltip title={false ? "" : "Missing eircode/address"}>*/}
                {/*        <span>*/}
                <Form.Item
                    name="addSameDeliveryAddress"
                    valuePropName="checked"
                    hidden={!showCompanyOptions}
                    {...tailFormItemLayout}
                >
                    <Checkbox onChange={() => {
                        setIsAddressNeeded(form.getFieldValue("addSameDeliveryAddress"));
                        if (form.getFieldValue("addSameDeliveryAddress")) {
                            let isAllFilled = true;
                            let emptyFields = "";
                            if (!form.getFieldValue("firstName")) {
                                isAllFilled = false;
                                emptyFields += "/First Name";
                            }
                            if (!form.getFieldValue("mobile")) {
                                isAllFilled = false;
                                emptyFields += "/Mobile Number";
                            }
                            if (!form.getFieldValue("companyEircode")) {
                                isAllFilled = false;
                                emptyFields += "/Company Eircode";
                            }
                            if (!form.getFieldValue("companyAddress")) {
                                isAllFilled = false;
                                emptyFields += "/Company Address";
                            }
                            if (!isAllFilled) {
                                message.warning("Please fill " + emptyFields.substring(1) + " to proceed")
                            }
                        }
                        form.validateFields(['firstName', 'mobile', 'companyEircode', 'companyAddress']);
                    }}>
                        Same as delivery address
                        <Tooltip overlayInnerStyle={{textAlign: "justify"}}
                                 placement={"right"}
                                 title={"Automatically adds default delivery address same as company address.\n" +
                                     "Once checked, First Name, Mobile Number, Company Eircode, and Company Address is required to proceed."}>
                            <QuestionCircleFilled style={{color: "grey", marginLeft: 2}}/>
                        </Tooltip>
                    </Checkbox>
                </Form.Item>
                {/*        </span>*/}
                {/*    </Tooltip>*/}
                {/*</Form.Item>*/}
                <Form.Item label="Email Verification Code" required>
                    <Row gutter={8}>
                        <Col span={14}>
                            <Form.Item
                                name="verificationCode"
                                noStyle
                                rules={[{
                                    required: true, message: 'Verification code cannot be empty!',
                                }, {
                                    validator: (_, value) => {
                                        const codeRegex = /^\d{6}$/; // must be 6 digits
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        if (!codeRegex.test(value)) {
                                            return Promise.reject(new Error('Incorrect format!'));
                                        }
                                        return Promise.resolve();
                                    },
                                }]}
                            >
                                <Input onKeyPress={(e) => {
                                    if (!/^\d$/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Button style={{width: "100%"}}
                                    disabled={isGetVerificationCodeButtonDisabled}
                                    loading={isGetVerificationCodeButtonLoading}
                                    onClick={getVerificationCode}
                            >
                                {isGetVerificationCodeButtonDisabled ? `${countdown}s` : 'Send Code'}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    {...tailFormItemLayout}
                    rules={[{
                        validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('You have to accept agreement')),
                    }]}
                >
                    <Checkbox className="required-checkbox">
                        I have read the <a onClick={(e) => {
                        e.preventDefault();
                        setIsAgreementModalOpen(true);
                    }}>Terms and Conditions</a>
                    </Checkbox>
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        Register
                    </Button>
                </Form.Item>
            </Form>
            <Modal width={800} title={<h2>Terms and Conditions</h2>} open={isAgreementModalOpen} onCancel={() => {
                setIsAgreementModalOpen(false)
            }} footer={null}>
                <h4>1. Introduction</h4>
                <p>
                    Welcome to dublincomputer.ie! These Terms and Conditions outline the rules and regulations for
                    the use of our website.
                </p>
                <h4>2. Acceptance of Terms</h4>
                <p>
                    By accessing or using dublincomputer.ie, you agree to comply with and be bound by these Terms
                    and Conditions. If you do not agree with any part of these terms, you must not use our website.
                </p>
                <h4>3. Changes to Terms</h4>
                <p>
                    We reserve the right to modify these Terms and Conditions at any time. Any changes will be
                    effective immediately upon posting on the website. Your continued use of the website after any
                    changes signifies your acceptance of the new Terms and Conditions.
                </p>
                <h4>4. User Responsibilities</h4>
                <p>
                    You agree to use the website only for lawful purposes and in a manner that does not infringe the
                    rights of others or restrict or inhibit anyone else's use of the website.
                </p>
                <h4>5. Intellectual Property</h4>
                <p>
                    All content, trademarks, and other intellectual property on the website are owned by or licensed
                    to dublincomputer.ie. You may not reproduce, distribute, or create derivative works from any
                    content without our express written consent.
                </p>
                <h4>6. Limitation of Liability</h4>
                <p>
                    To the fullest extent permitted by law, dublincomputer.ie will not be liable for any indirect,
                    incidental, special, or consequential damages arising from your use of the website or any
                    content therein.
                </p>
                <h4>7. Governing Law</h4>
                <p>
                    These Terms and Conditions shall be governed by and construed in accordance with the law. Any
                    disputes arising from these terms will be subject to the exclusive jurisdiction of the courts of
                    Ireland.
                </p>
                <h4>8. Contact Us</h4>
                <p>
                    If you have any questions about these Terms and Conditions, please contact us at
                    info@dublincomputer.ie
                </p>
            </Modal>
        </div>
    </div>);
}

export default Register;
