import React, {useEffect, useState} from 'react';
import {
    Layout,
    Space,
    Divider,
    Input,
    Menu,
    Breadcrumb,
    Tag,
    Row,
    Col,
    Tooltip,
    Popconfirm,
    message,
    Badge,
    AutoComplete,
    ConfigProvider
} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import "./Index.css";
import addrLogo from "../resource/addr.png";
import emailLogo from "../resource/email.png";
import phoneLogo from "../resource/phone.png";
import userLogo from "../resource/user.png";
import mainLogo from "../resource/main.png";
import likeLogo from "../resource/like.png";
import cartLogo from "../resource/cart.png";
import {observer} from "mobx-react-lite";
import navPathStore from "../store/NavPath.Store.js";
import userInfoStore from "../store/UserInfo.Store";
import http from "../framework/http.js";

// import categoryStore from "../store/Category.Store.js";


function Index() {
    const navigate = useNavigate();
    const navbarItems = [{
        label: <Link to="/">HOME</Link>, key: 'HOME',
    }, {
        label: <Link to="/category/1">APPLE</Link>, key: '1',
    }, {
        label: <Link to="/category/2">SAMSUNG</Link>, key: '2',
    }, {
        label: <Link to="/category/3">HUAWEI</Link>, key: '3',
    }, {
        label: <Link to="/category/505">MI</Link>, key: '505',
    }, {
        label: <Link to="/category/14">NOKIA</Link>, key: '14',
    }, {
        label: <Link to="/category/192">GOOGLE</Link>, key: '192',
    }, {
        label: <Link to="/category/146">ONEPLUS</Link>, key: "146",
    }, {
        label: <Link to="/category/55">ALCATEL</Link>, key: "55",
    }, {
        label: <Link to="/category/5">OPPO</Link>, key: "5",
    }, {
        label: <Link to="/category/483">COMPUTER PARTS</Link>, key: "483",
    }, {
        label: <Link to="/category/510">TOOLS</Link>, key: "510",
    }, {
        label: <Link to="/category/509">ACCESSORIES</Link>, key: "509",
    },];
    const {Header, Content, Footer} = Layout;
    const {Search} = Input;
    const [searchOptions, setSearchOptions] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searching, setSearching] = useState();
    const [navbarSelected, setNavbarSelected] = useState();
    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        // if (location.pathname === '/') {
        //     navPathStore.setNavPath([]);
        //     setNavbarSelected(["HOME"]);
        // } else if (params.categoryId) {
        //     setNavbarSelected([params.categoryId]);
        // }
        if (params.categoryId) {
            setNavbarSelected([params.categoryId]);
        } else if (location.pathname === '/') {
            navPathStore.setNavPath([]);
            setNavbarSelected(["HOME"]);
        } else {
            navPathStore.setNavPath([]);
            setNavbarSelected([]);
        }
    }, [location])

    async function onLogoutConfirm() {
        const res = await http.post("/logout");
        if (res.data && res.data.code === 200) {
            window.localStorage.removeItem("token");
            userInfoStore.setUserInfo({activationStatus: null, displayName: null});
            message.success("logged out");
            window.location.reload();
        }
    }

    const onSearch = async (value) => {
        console.log(value);
        setSearching(true);
        if (value.trim()) {
            const res = await http.get("/product/search", {params: {keyword: value.replace(/\s+/g, '')}});
            if (res.data?.code === 200) {
                setSearchOptions(res.data.data.map((productItem) => {
                    return {
                        label: <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                height: 70,
                                transition: "background-color 0.3s", // 添加过渡效果
                                cursor: "pointer", // 鼠标悬停时显示手形光标
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#f0f0f0"}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
                        >
                            <img alt="" src={productItem.cover ?
                                http.getUri() + productItem.cover :
                                http.getUri() + "images/default.png"}
                                 style={{height: "100%"}}/>
                            <p title={productItem.title}
                               style={{
                                   marginLeft: 15,
                                   fontWeight: "bold",
                                   color: "#333333",
                                   whiteSpace: "normal",
                               }}>
                                {productItem.title}
                            </p>
                        </div>,
                        value: productItem.id,
                    }
                }));

            }
        } else {
            setSearchOptions([]);
        }
        setSearching(false);
    };
    return (<Layout style={{minHeight: "100vh"}}>
        <Header style={{
            background: "#0952a2", height: 50, lineHeight: "50px", color: "white",
        }}>
            <Row style={{maxWidth: 1210, margin: "auto"}} justify={"end"} wrap={false}>
                <Col xs={0} sm={0} md={0} lg={0} xl={16}>
                    <Space size={45}>
                        <p style={{margin: 0, display: "inline", whiteSpace: "nowrap"}}>
                            <img src={addrLogo} alt="" style={{marginRight: 5, position: "relative", top: 1}}/>
                            40 Canal Walk，ParkWest Business Park，Dublin12，Ireland</p>
                        <p style={{margin: 0, display: "inline", whiteSpace: "nowrap"}}>
                            <img src={emailLogo} alt="" style={{marginRight: 5, position: "relative", top: 1}}/>
                            info@dublincomputer.ie
                        </p>
                        <p style={{margin: 0, display: "inline", whiteSpace: "nowrap"}}>
                            <img src={phoneLogo} alt="" style={{marginRight: 5, position: "relative", top: 2}}/>
                            01-6301214
                        </p>
                    </Space>
                </Col>
                <Col flex={"auto"}>
                    <div>
                        {userInfoStore.activationStatus === 1 && <Tooltip title="Activated trader account">
                            <Tag style={{cursor: "pointer"}} color={"rgb(170, 0, 0)"}>Activated</Tag>
                        </Tooltip>}
                        {userInfoStore.activationStatus === 2 &&
                            <Tooltip overlayInnerStyle={{textAlign: "justify"}} title={<>
                                An unactivated trader account can only browse in wholesale prices but cannot place an
                                order.<br/>
                                Contact us at +353 1 6301214 or email: info@dublincomputer.ie to activate your trader
                                account.
                            </>}>
                                <Tag style={{cursor: "pointer"}} color={"grey"}>Unactivated</Tag>
                            </Tooltip>}
                    </div>
                </Col>
                <Col flex={"auto"} style={{display: "flex", justifyContent: "flex-end"}}>
                    <Space>
                        <p style={{margin: 0, display: "inline", whiteSpace: "nowrap"}}>
                            <img src={userLogo} alt="" style={{marginRight: 8, position: "relative", top: 3}}/>
                        </p>
                        {userInfoStore.activationStatus===null ?
                            <Space split={<Divider type="vertical" style={{background: "gray"}}/>}>
                                <a style={{color: "white", textDecoration: "underline"}} onClick={() => {
                                    navigate("/login", {state: {from: location.pathname}});
                                }}>Login</a>
                                <a style={{color: "white", textDecoration: "underline"}} onClick={() => {
                                    navigate("/register", {state: {from: location.pathname}});
                                }}>Register</a>
                            </Space> : <Space split={<Divider type="vertical" style={{background: "gray"}}/>}>
                                <a style={{color: "white", textDecoration: "underline"}} onClick={() => {
                                    navigate("/userCenter/orderManager");
                                }}>{userInfoStore.displayName}</a>
                                <Popconfirm title="Sure to logout?" okText="confirm" cancelText="cancel"
                                            onConfirm={onLogoutConfirm}>
                                    <a style={{color: "white", textDecoration: "underline"}}>Sign out</a>
                                </Popconfirm>
                            </Space>}
                    </Space>
                </Col>
            </Row>
        </Header>
        <Content>
            <div style={{
                height: 120, display: "flex", justifyContent: "center", alignItems: "center"
            }}>
                <Space size={180} style={{
                    position: "relative", left: -50
                }}>
                    <img src={mainLogo} alt="" style={{cursor: 'pointer'}} onClick={() => {
                        navigate("/")
                    }}/>
                    {/*comment out the onSearch in AutoComplete element to disable onChange request*/}
                    <ConfigProvider theme={{
                        components: {
                            Input: {
                                hoverBorderColor: 'f8eaec',
                                activeBorderColor: "f8eaec",
                                activeShadow: "0 0 10px 0 rgba(231, 0, 20, 0.2)",
                            }
                        },
                    }}>
                        <AutoComplete style={{left: -15}}
                                      options={searchOptions}
                                      onSelect={(value, option) => {
                                          console.log(value)
                                          navigate("/product/" + value);
                                      }}
                                      onSearch={onSearch}
                                      value={searchKeyword}
                        >
                            <Input className="inputSearchBox" size="large" allowClear placeholder="search..."
                                   onChange={(e) => {
                                       setSearchKeyword(e.target.value);
                                   }}
                                // loading={searching}
                                   suffix={<SearchOutlined style={{color: "#e70014", height: 24}} onClick={(e) => {
                                       e.preventDefault();
                                   }}/>}
                            />
                        </AutoComplete>
                    </ConfigProvider>
                    <Space size={35}>
                        <img src={likeLogo} alt="" style={{transform: "scale(1.1)", cursor: "pointer",visibility:"hidden"}}/>
                        <span style={{cursor: "pointer"}} title={"Cart"} onClick={()=>{navigate("/cart")}}>
                            <Badge showZero size="small" color="#e70216" offset={[7, -0]}
                                   count={!userInfoStore.cartAmount?0:userInfoStore.cartAmount}>
                                <img src={cartLogo} alt="" style={{transform: "scale(1.1)"}}/>
                            </Badge>
                        </span>
                    </Space>
                </Space>
            </div>
            <Menu id="navbar" mode="horizontal" items={navbarItems} selectedKeys={navbarSelected} style={{
                display: "flex", justifyContent: "center", fontWeight: "bold", position: "sticky", top: 0, zIndex: 999
            }}/>
            {navPathStore.getNavPath().length > 1 ?
                <Breadcrumb id="navPath" separator=">" items={navPathStore.getNavPath()} style={{
                    background: "#0952a2", height: 42, display: "flex", alignItems: "center", paddingLeft: "18vw"
                }}/> : <Breadcrumb id="navPath" separator=">" items={null} style={{
                    background: "#0952a2", height: 42, display: "flex", alignItems: "center", paddingLeft: "18vw"
                }}/>}
            <div style={{display: "flex", justifyContent: "center",}}><Outlet/></div>
        </Content>
        <Footer style={{background: "gray"}}>
            footer
        </Footer>
    </Layout>);
}

export default observer(Index);
