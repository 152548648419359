import axios from "axios";
import {message} from "antd";
import history from "./history.js";
import userInfoStore from "../store/UserInfo.Store";

const http = axios.create({
    baseURL: window.location.protocol + "//194.26.213.249/api/"
    // baseURL: 'http://localhost:8080/',
    // timeout: 10000
});

http.interceptors.request.use((request) => {
    const token = window.localStorage.getItem("token");
    if (token) {
        request.headers.token = token;
    }
    return request;
});

http.interceptors.response.use((response) => {

    if (window.location.pathname !== "/login" && response.data?.code === 401) {
        window.localStorage.removeItem("token");
        userInfoStore.setUserInfo({activationStatus: null, displayName: null});
        // message.destroy();
        // message.warning("login expired, please login again");
        history.push("/login", {from: window.location.pathname});
    }

    return response;
});

export default http;
