import {unstable_HistoryRouter as HistoryRouter, Routes, Route, Navigate} from "react-router-dom";
import history from "./framework/history.js";
import Index from "./component/Index.js";
import NotFound from "./404.js";
import Home from "./component/Home.js";
import {lazy, Suspense} from "react";
import Register from "./component/Register";


// const CategoryViewer = lazy(() => import("./component/CategoryViewer.js"));
import CategoryViewer from "./component/CategoryViewer";

const ProductViewer = lazy(() => import("./component/ProductViewer"));
const Login = lazy(() => import("./component/Login.js"));
const RegisterPage = lazy(() => import("./component/Register.js"));
const UserCenter = lazy(() => import("./component/UserCenter.js"));
const ProfileManager = lazy(() => import("./component/ProfileManager.js"));
const AddressManager = lazy(() => import("./component/AddressManager.js"));
const OrderManager = lazy(() => import("./component/OrderManager.js"));
const ChangePassword = lazy(() => import("./component/ChangePassword.js"));
const OrderBuilder = lazy(() => import("./component/OrderBuilder.js"));
const CartManager = lazy(() => import("./component/CartManager.js"));


// function LoginChecker({type, children}) {
//     if (window.localStorage.getItem("b-token")) {
//         return <>{children}</>
//     } else {
//         if (type === "admin") {
//             return <Navigate to="/admin/login" replace/>
//         } else {
//             return <Navigate to="/login" replace/>
//         }
//     }
// }

function App() {
    return (
        <div>
            <Suspense fallback={<div></div>}>{/*for now, not to show any loading tip*/}
                <HistoryRouter history={history}>
                    <Routes>
                        <Route path="/" element={<Index/>}>
                            <Route index element={<Home/>}/>
                            <Route path="/category/:categoryId?" element={<CategoryViewer/>}/>
                            <Route path="/category/:categoryId?/product/:productId?" element={<ProductViewer/>}/>
                            <Route path="/product/:productId?" element={<ProductViewer/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/register" element={<RegisterPage/>}/>
                            <Route path="/cart" element={<CartManager/>}/>
                            <Route path="/orderPreview" element={<OrderBuilder/>}/>
                            <Route path="/userCenter" element={<UserCenter/>}>
                                <Route path="profileManager" element={<ProfileManager/>}/>
                                <Route path="addressManager" element={<AddressManager/>}/>
                                <Route path="orderManager" element={<OrderManager/>}/>
                                <Route path="changePassword" element={<ChangePassword/>}/>
                            </Route>
                            <Route path="/stripe/success" element={<div>Payment Success!</div>}/>
                            <Route path="/stripe/cancel" element={<div>Payment Failed!</div>}/>
                        </Route>
                        <Route path="/*" element={<NotFound/>}/>
                    </Routes>
                </HistoryRouter>
            </Suspense>
        </div>
    );
}

export default App;
