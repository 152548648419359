import {makeAutoObservable, runInAction, toJS} from "mobx";
import http from "../framework/http.js";

class CategoryStore {
    category = null;

    constructor() {
        makeAutoObservable(this);
        this.loadCategory();
    }

    getCategory = () => {
        return toJS(this.category);
    };

    loadCategory = async () => {
        const res = await http.get("/category/list");
        if (res.data && res.data.code === 200) {
            const category = new Map();
            for (const item of res.data.data) {
                category.set(item.id, item);
            }
            runInAction(() => {
                this.category = category;
            })
        }
    };


}

console.log("category store built")

let categoryStore = new CategoryStore();
export default categoryStore;
