import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function NotFound() {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5); // 设置初始倒计时为5秒

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prev => prev - 1);
        }, 1000);

        const redirectTimer = setTimeout(() => {
            navigate('/', { replace: true });
        }, 5000);

        // 清理计时器
        return () => {
            clearInterval(timer);
            clearTimeout(redirectTimer);
        };
    }, []);

    return (
        <div>
            404 Not Found. Redirecting to home page in {countdown} seconds...
        </div>
    );
}

export default NotFound;
