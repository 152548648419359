import {makeAutoObservable,toJS} from "mobx";

class NavPath {
    // navPath = [{title: <a href="" style={{fontWeight: "bold"}}>Home</a>}];
    navPath = [];

    constructor() {
        makeAutoObservable(this)
    }

    setNavPath(navPath) {
        this.navPath = navPath;
    }

    getNavPath() {
        return toJS(this.navPath);
    }


}

let navPathStore = new NavPath();

export default navPathStore;
