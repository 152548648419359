import React, {useEffect, useState} from 'react';
import {Carousel} from 'antd';
import advertisementImg from "../resource/ad.png";
import http from "../framework/http";

function Home() {
    const [carouselContent, setCarouselContent] = useState();
    useEffect(() => {
        async function loadCarousel() {
            const res = await http.get("/slide/list");
            if (res.data && res.data.code === 200) {
                const slides = res.data.data;
                let imgArr = [];
                slides.map((slide) => {
                    imgArr.push(<img key={slide.id} src={http.getUri() + slide.picture} alt=""/>)
                });
                setCarouselContent(imgArr);
            }
        }
        loadCarousel();
    }, []);


    return (
        <div>
            <img src={advertisementImg} alt=""/>
            <Carousel>
                <Carousel autoplay>
                    {carouselContent}
                </Carousel>
            </Carousel>
        </div>
    );
}

export default Home;
